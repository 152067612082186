import Script from "next/script";

const GoogleTagManagerScript = ({ object }) => {
  const googleTagManagerId = object?.id;

  if (
    (process && process.env.NODE_ENV === "development") ||
    !googleTagManagerId
  ) {
    return null;
  }

  return (
    <>
      <Script
        id="google-tag-manager-script"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${googleTagManagerId}');
          `,
        }}
      />

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src={"https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"} height="0" width="0" className="display-none, visibility-none"></iframe>`,
        }}
      />
    </>
  );
};

export default GoogleTagManagerScript;
