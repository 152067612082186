import Script from "next/script";

const GoogleTagScript = ({ object }) => {
  const googleTagId = object?.id;

  if ((process && process.env.NODE_ENV === "development") || !googleTagId) {
    return null;
  }

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}
      />

      <Script
        id="google-tag-script"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${googleTagId}');`,
        }}
      />
    </>
  );
};

export default GoogleTagScript;
