import Script from "next/script";

const HotjarScript = ({ object }) => {
  const hotjarId = object?.id;

  if ((process && process.env.NODE_ENV === "development") || !hotjarId) {
    return null;
  }

  const hotjarVersion = object?.version || 6;

  return (
    <>
      <Script
        id="hotjar-script"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:${hotjarVersion}};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
        }}
      />
    </>
  );
};

export default HotjarScript;
