import GoogleTagManagerScript from "./GoogleTagManagerScript";
import HotjarScript from "./HotjarScript";
import GoogleTagScript from "./GoogleTagScript";

const customScriptsOptions = {
  googleTagManager: GoogleTagManagerScript,
  googleTag: GoogleTagScript,
  hotjar: HotjarScript,
};

const CustomScripts = ({ site }) => {
  return (
    <>
      {site?.config.map((config) => {
        const ScriptComponent = customScriptsOptions[config.key];

        if (ScriptComponent)
          return <ScriptComponent key={config.key} {...config} />;

        return null;
      })}
    </>
  );
};

export default CustomScripts;
